import "@material-ui/styles";

import React from "react";
import ReactDOM from "react-dom";

import { Website } from "./Website";

const main = document.createElement("div");

ReactDOM.render(<Website />, main);
document.getElementsByTagName("body")[0].appendChild(main);

